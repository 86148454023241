export const productBindingColumns = [
  { title: '第三方平台', dataIndex: 'thirdPlatform', key: 'thirdPlatform' },
  { title: '绑定课程/会员', dataIndex: 'businessName', key: 'businessName'},
  { title: '课程/会员ID', dataIndex: 'businessNo', key: 'businessNo'},
  { title: '绑定方式', dataIndex: 'bindType', key: 'bindType'},
  { title: '第三方商品ID/SKU ID', dataIndex: 'productId', key: 'productId' },
  { title: '创建时间', dataIndex: 'createTime', key: 'createTime' },
  {
    title: '操作',
    key: 'operation',
    fixed: 'right',
    width: 100,
    scopedSlots: { customRender: 'action' },
  },
]

export const thirdPlat = [
  { name: '小商店', val: 1 },
  { name: '抖店', val: 2 },
  { name: '快手', val: 3 },
  { name: '小红书', val: 4 },
]

export const businessTypeList = [
  { type: '课程', val: 1 },
  { type: '会员', val: 2 },
]
export const bindTypeList = [
  { type: '商品ID', val: 2 },
  { type: 'SKU ID', val: 1 },
]

export const businessTypeMap = new Map([
  [ 1, '课程'],
  [ 2, '会员'],
  [ undefined,'会员']
])

export const mallOrderColumns = [
  {
    title: '商品名称',
    dataIndex: 'goodsName',
    key: 'goodsName',
    width: '12%'
  },
  {
    title: '订单编号',
    dataIndex: 'orderNo',
    key: 'orderNo',
    width: '9%',
  },
  {
    title: '商品类型',
    dataIndex: 'goodsType',
    key: 'goodsType',
    width: '4%',
    scopedSlots: { customRender: 'goodsType' },
  },
  {
    title: '商品金额',
    dataIndex: 'price',
    key: 'price',
    width: '8%',
    scopedSlots: { customRender: 'price' },
  },
  {
    title: '实付金额',
    dataIndex: 'realPrice',
    key: 'realPrice',
    width: '5%',
    scopedSlots: { customRender: 'realPrice' },
  },
  {
    title: '用户账号',
    dataIndex: 'userName',
    key: 'userName',
    width: '5%'
  },
  {
    title: '支付方式',
    dataIndex: 'payType',
    key: 'payType',
    width: '5%',
    scopedSlots: { customRender: 'payType' },
  },
  {
    title: '订单状态',
    dataIndex: 'state',
    key: 'state',
    width: '5%',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: '下单时间',
    dataIndex: 'orderTime',
    width: '8%',
    key: 'orderTime',
  },
  {
    title: '收货地址',
    dataIndex: 'receiptAddress',
    key: 'receiptAddress',
    scopedSlots: { customRender: 'receiptAddress' },
    width: '10%'
  },
  {
    title: '快递公司',
    dataIndex: 'trackingName',
    key: 'trackingName',
    with: '5%'
  },
  {
    title: '快递单号',
    dataIndex: 'trackingNumber',
    key: 'trackingNumber',
    width: '8%'
  },
  {
    title: '发货状态',
    dataIndex: 'shippingStatus',
    key: 'shippingStatus',
    width: '5%',
    scopedSlots: { customRender: 'shippingStatus' },
  },
  {
    title: '操作',
    key: 'actions',
    fixed: 'right',
    width: 200,
    scopedSlots: { customRender: 'actions' },
  },
]

export const payTypeMap = new Map([
  [1, '微信'],
  [2, '支付宝'],
  [3, '余额'],
  [4, '积分'],
])

export const ORDER_STATUS_APPLY_REFUND = 4
export const ORDER_STATUS_REFUNDING = 5
export const ORDER_STATUS_REFUND_FAILED = 7

export const getOrderStateMap = new Map([
  [1, '待支付'],
  [2, '支付成功'],
  [3, '支付失败'],
  [4, '申请退款中'],
  [5, '退款中'],
  [6, '退款成功'],
  [7, '退款失败'],
  [8, '订单已取消'],
])

export const getShippingStatusMap = new Map([
  [1, '无需发货'],
  [2, '待发货'],
  [3, '已发货'],
  [4, '已送达'],
])

export const goodsTypeList = [
  {
    key: -1,
    value: '全部'
  },
  {
    key: '1',
    value: '虚拟商品'
  },
  {
    key: '0',
    value: '实物商品'
  }
]

export const listenerOrderColumns = [
  {
    title: '订单编号',
    dataIndex: 'orderNo',
    key: 'orderNo',
    width: '8%',
  },
  {
    title: '商品名称',
    dataIndex: 'businessName',
    key: 'businessName',
    width: '12%',
  },
  {
    title: '用户手机号',
    dataIndex: 'userName',
    key: 'userName',
    width: '6%',
  },
  {
    title: '支付金额',
    dataIndex: 'realPrice',
    key: 'realPrice',
    width: '4%',
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatusText',
    key: 'orderStatusText',
    width: '4%',
  },
  {
    title: '下单时间',
    dataIndex: 'createTime',
    width: '5%',
    sorter: true,
    key: 'createTime'
  },
  {
    title: '支付时间',
    dataIndex: 'payTime',
    width: '5%',
    sorter: true,
    key: 'payTime'
  }, 
]